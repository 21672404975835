import React, { useRef } from 'react'
// import cryptoHero from '../../assets/crypto/cryptoHero.png'
// import cryptoPage from '../../assets/crypto/cryptoPage.png'
// import africaMap from '../../assets/Doted-African-Map.png'
// import nigeriaFlag from '../../assets/nigeria-flag.png'
// import kenyaFlag from '../../assets/kenya-flag.png'
// import ghanaFlag from '../../assets/ghana-flag.png'
// import saFlag from '../../assets/sa-flag.png'
// import cardIcon from '../../assets/about-us/card-icon.png'
// import bundleImg from '../../assets/crypto/bundle.png'
// import fluidcoinsImg from '../../assets/crypto/fluidcoin.png'
// import buycoinsImg from '../../assets/crypto/buycoins.png'
// import crypstoneImg from '../../assets/crypto/crypstone.png'
import ReactElasticCarousel from 'react-elastic-carousel'

export default function CryptoExchangesPage() {
    const items = [
        {
            "title":"Bundle Africa",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648769452/idPass-web-assets/crypto/bundle_xzgxe2.png",
            "story": "Identitypass verification solution basically secures a safe transaction on Bundle Africa’s platform. Additionally, Bundle Africa's clients are easily verified and hackers or fraudsters are being kept out from intruding into a customer’s account.",
        },
        {
            "title":"Fluidcoins",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648769453/idPass-web-assets/crypto/fluidcoin_fehbdg.png",
            "story": "Through Identitypass’s verification products, Fluidcoin easily reduces the incidence of Chargebacks and enables customers to get rid of identity theft incidences on their accounts.",
        },
        {
            "title":"Buy Coins",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648769453/idPass-web-assets/crypto/buycoins_qlcdhn.png",
            "story": "Identitypass allows Buycoins end-users to smoothly authenticate on different platforms from web to mobile or vice versa without any additional identity verifications. With Identitypass, Buycoin’s users are easily authenticated, able to access their account, and seamlessly perform their transactions on their devices from any location.",
        },
        {
            "title":"Crypstone Global Resources Limited",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648769453/idPass-web-assets/crypto/crypstone_ojjpww.png",
            "story": "Identity Pass enables Crypstone to create a convenient and secure way for customers to access their accounts, and help them scale through the inconvenience experienced with added security measures such as OTPs. Through the combination of AI-based technology with the expertise of fraud-spotting intelligence, crypto transactions on Crypstone are safe and free from fraud.",
        },
    ]
    const itemsPerPage = 1
    const carouselRef = useRef(null);
    const totalPages = Math.ceil(items.length / itemsPerPage)
    let resetTimeout;
  return (
    <div>
        <section className="mobility-hero">
            <div className="row align-items-center">
                <div className="col-md-6 order-md-2">
                    <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648769454/idPass-web-assets/crypto/cryptoHero_wylexr.png" alt="" className='w-100' />
                </div>
                <div className="col-md-6 col-lg-5 offset-lg-1 py-5 order-md-1 px-4">
                    <h6>VERIFICATION AND SECURITY SERVICE FOR</h6>
                    <h1>Crypto Exchanges</h1>
                    <p>
                        The onboarding experience for crypto exchanges must be quick, secure, 
                        and seamless for customers. Your business must also be fully compliant 
                        and have robust fraud prevention measures that are fundamental for crypto 
                        exchanges. Identitypass identity verification solutions prevent fraud, 
                        guarantee compliance, increase conversion, and ensure you always know 
                        who you are dealing with as a business.
                    </p>
                    <a className="py-3 px-3 px-md-5 btn btn-white mt-5" href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" target="_blank" rel="noreferrer">Talk to Sales</a>
                </div>
            </div>
        </section>

        <section className='py-5'>
            <div className="container py-5">
                <div className="row align-items-center">
                    <div className="col-md-7 order-md-2">
                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648769455/idPass-web-assets/crypto/cryptoPage_je2wp3.png" alt="" className='w-100' />
                    </div>
                    <div className="col-md-5 order-md-1 mt-4">
                        <h5>WHAT WE DO</h5>
                        <h2>
                            Identitypass adequate verification enables you to implement an adaptable risk-based 
                            approach to identity verification.
                        </h2>
                        <p>
                            Using Identitypass’s robust identity verification measures encourages your customers, 
                            partners, and regulators to take your business activities seriously.
                        </p>
                        <a className="py-3 px-3 px-md-5 btn btn-purple mt-5" href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer">Get Started</a>
                    </div>
                </div>
            </div>
        </section>

        
        <section className="id-checker-widget mobility-verif how-face-works py-5" >
                <div className="container">
                    <div className="col-md-8 mx-auto text-md-center py-5">
                        <h2>Our Crypto Exchange Endpoints.</h2>
                        <p>
                            Three (3) major verification endpoints for mobility that can be merge into one.
                        </p>
                    </div>
                    <div className=" core-values mobility-endpoints mb-5">
                        <div className="row">
                            <div className="col-md-4 mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648738944/idPass-web-assets/about-us/card-icon_gknouc.png" className="card-icon" alt="" />
                                        <h4>NIN Verification</h4>
                                        <p>Verify a crypto trader through his/her National Identification Number right from onboarding.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648738944/idPass-web-assets/about-us/card-icon_gknouc.png" className="card-icon" alt="" />
                                        <h4>Credit Bereau</h4>
                                        <p>Ascertain the accuracy status of a customer interested in trading on your platform.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648738944/idPass-web-assets/about-us/card-icon_gknouc.png" className="card-icon" alt="" />
                                        <h4>BVN + Phone number + NIN</h4>
                                        <p>Know the authenticity of a customer’s identity by verifying the three identifiers at once.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobility-slider'>
                        <ReactElasticCarousel enableAutoPlay={true} autoPlaySpeed={3000}
                            ref={carouselRef}
                            onNextEnd={({ index }) => {
                                clearTimeout(resetTimeout)
                                if (index + 1 === totalPages) {
                                    resetTimeout = setTimeout(() => {
                                       carouselRef?.current?.goTo(0)
                                    }, 3000)
                                }
                            }}
                            itemsToShow={itemsPerPage}
                        >
                            {items.map(value => {
                                return(
                                    <div className="row align-items-center">
                                        <div className="col-md-7 order-md-2">
                                            <img src={value.img} alt="" className='w-100' />
                                        </div>
                                        <div className="col-md-5 py-5 px-md-5 order-md-1 px-4">
                                            <h5>HOW THEY LEVERAGE IDENTITYPASS</h5>
                                            <h3>{value.title}</h3>
                                            <p>
                                                {value.story}
                                            </p>
                                            <a className="py-3 px-3 px-lg-5 btn btn-purple mt-5" href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" target="_blank" rel="noreferrer">Contact Sales</a>
                                        </div>
                                    </div>
                                )
                            })}
                        </ReactElasticCarousel>
                    </div>
                </div>
            </section>

        <section className="id-verif-steps py-5">
            <div className="container">
                <div className=" mb-5 text-center line">
                    <p>
                        How you can connect with us
                    </p>
                    <h2>How the process works</h2>
                </div>
                <div className="row text-center">
                    <div className="col-md-4 mt-3">
                        <span>
                            <h3> 1 </h3>
                        </span>
                        <h5>Via APIs</h5>
                        <p>You can easily connect with any of our endpoint using the available APIs</p>
                    </div>
                    <div className="col-md-4 mt-5">
                        <span className="li">
                            <h3> 2 </h3>
                        </span>
                        <h5>Via SDKs</h5>
                        <p>We have our SDKs in FLUTTER, REACT NATIVE and REACT JS.</p>
                    </div>
                    <div className="col-md-4 mt-3">
                        <span>
                            <h3> 3 </h3>
                        </span>
                        <h5>From the Portal</h5>
                        <p>We offer manual verification from our dashboard.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5">
            <div className="container">
                <div className="col-md-8 text-center mx-auto pt-5  pb-3">
                    <h1>Our Pricing</h1>
                    <p>
                        Our enterprise pricing is custom based. Schedule a call 
                        with us an tell us your use cases and we will tailor 
                        your pricing.
                    </p>
                </div>
            </div>
        </section>

        <section  className="pricing-card py-md-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-4 mt-4 p-md-0">
                        <div className="card">
                            <div className="card-body">
                                <i class="ri-loader-4-line loader" />
                                <h5>Standard Pricing</h5>
                                <small>You can pay as you make your calls</small>
                                <h3>$0.048</h3>
                                <h6>Per Successful API Call</h6><hr />
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p> Free monthly credit.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Access all APIs documentation and end points.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Technical Support</p>
                                </div>
                                <a className="py-3 btn btn-purple" 
                                    href="https://dashboard.myidentitypass.com/login" 
                                    target="_blank" rel="noreferrer"
                                >Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 mt-4 p-md-0 blue">
                        <div className="card">
                            <div className="card-body">
                                <div className="header">
                                    <i class="ri-loader-4-line loader" />
                                    <span>Recommended</span>
                                </div>
                                <h5>Premium Access</h5>
                                <small>With premium plan comes with more access</small>
                                <h3>Custom</h3>
                                <h6>Seat and pick endpoint to access</h6><hr />
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p> Request for other biometrics needs for your business and we will get it Delivered.</p>
                                </div>
                                <a className="py-3 btn btn-white" 
                                    href="https://dashboard.myidentitypass.com/login" 
                                    target="_blank" rel="noreferrer"
                                >Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-4 p-md-0">
                        <div className="card">
                            <div className="card-body">
                                <div className="header">
                                    <i class="ri-loader-4-line loader" />
                                    <span>Recommended</span>
                                </div>
                                <h5>Enterprice Pricing</h5>
                                <small>This pricing plan gives you every access to our APIs</small>
                                <h3>Full Access</h3>
                                <h6>Get full Access to all our Endpoints</h6><hr />
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p> Biometrics liveliness checker API.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>ID-Face Biometrics API.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Authentication API.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                     <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Verification API and many more.</p>
                                </div>
                                <a className="py-3 btn btn-purple" 
                                    href="https://dashboard.myidentitypass.com/login" 
                                    target="_blank" rel="noreferrer"
                                >Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="across-africa-area py-5">
            <div className="container py-3">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h3>We can Easily Verify Your Customers</h3>
                        <p>1. <span style={{fontFamily:"GT-bold"}}>National IDs</span> -  Verify your customers national record</p>
                        <p>2. <span style={{fontFamily:"GT-bold"}}>Bank verification numbers</span> - We can inform you about the banking details of your customers</p>
                        <p>3. <span style={{fontFamily:"GT-bold"}}>Phone Number verification</span> - Verify or onboard your customers using the phone number</p>
                        <p>4. <span style={{fontFamily:"GT-bold"}}>Credit Histories</span> - Verify the credit histories of your customer</p>
                        <p>5. <span style={{fontFamily:"GT-bold"}}>Tax record</span> - Verify whether the tax record of your customer is accurate</p>
                        <div className="row pe-2">
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716892/idPass-web-assets/nigeria-flag_cwwg8t.png" alt="" className="w-100" />
                                <h5>Nigeria</h5>
                            </div>
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716892/idPass-web-assets/kenya-flag_gqxlg2.png" alt="" className="w-100" />
                                <h5>Kenya</h5>
                            </div>
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716891/idPass-web-assets/ghana-flag_uzl7bg.png" alt="" className="w-100" />
                                <h5>Ghana</h5>
                            </div>
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716893/idPass-web-assets/sa-flag_uagypd.png" alt="" className="w-100"/>
                                <h5>South Africa</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716890/idPass-web-assets/Doted-African-Map_qxyxfb.png" alt="" className="w-100 mt-4" />
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
