import React, { useEffect, useState } from 'react'
import ReactElasticCarousel from 'react-elastic-carousel'
import { decodeHTML } from 'entities';

export default function Blogcard() {
    const [articles, setArticles] = useState([]);

    // const userName = `my-identity-pay`
    // const mediumURL = `https://medium.com/feed/${userName}`
    // const rssURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/my-identity-pay"

    // const mediumURL = "https://www.convertjson.com/cgi-bin/url-to-json.php?callback=loadDataAndRun&url=https://medium.com/feed/my-identity-pay"

    // var myHeaders = new Headers();
    // myHeaders.append("Cookie", "ARRAffinity=87619c1bcf9edccdaac372052beb202b1e23bea2f0bb9af0a9b2e6d69dff1808; ARRAffinitySameSite=87619c1bcf9edccdaac372052beb202b1e23bea2f0bb9af0a9b2e6d69dff1808");
    
    // var requestOptions = {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'follow'
    // };
    
    let removeTags =  /<(.*?)>/g
    
    useEffect(() => {

        const loadArticles = async () => {
            // fetch("https://www.convertjson.com/cgi-bin/url-to-json.php?callback=loadDataAndRun&url=https://medium.com/feed/my-identity-pay", requestOptions)
            // .then((res) => res.text())
            // .then((data)=>{
            //     setArticles(data)
            //     console.log(data)
            // })
            // .catch(error => console.log('error', error));
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://cms-backend.myidentitypass.com/resource/blog/collections", requestOptions)

            // fetch(rssURL, { headers: { Accept: "application/json" } })
            .then((res) => res.json())
            .then((data)=>{
                setArticles(data)
            })
            .catch((error) => console.log(error));
        };
        loadArticles();
    }, []);


    
    return (
        <div className="blog-card-area py-5">
            <div className="container my-5">
                <div className="col-md-6 mx-auto text-center pb-5">
                    <h2>Our Blog</h2>
                </div>

                <div className="col-md-11 mx-auto">
                    
                    {/* displays only on systems */}
                    <div className="row d-none d-lg-flex">
                        {articles?.slice(0,3).map((newArticle, index)=>{
                            return(
                                <div key={index} className="col-md-4 mt-3">
                                    <img src={newArticle?.publication?.image_banner} alt="" />
                                    <div style={{minHeight:"100px"}}>
                                        <h4 className="mt-4">{newArticle?.publication?.heading}.</h4>
                                    </div>
                                    <p className='article'>
                                        {/* {newArticle.description.substr(240)} */}
                                        {decodeHTML(newArticle?.publication?.body?.replace(removeTags, "")) }
                                    </p>
                                    <div className="dateTime">
                                        <i class="ri-calendar-line me-2"/>
                                        <span>
                                            <small>{newArticle?.publication?.published_dated}</small>
                                            {/* <small className="ms-3">
                                                {newArticle.author}
                                            </small> */}

                                        </span>
                                    </div>
                                    <a href={`https://blog.myidentitypass.com/fullArticle/${newArticle?.slug}`} target="_blank" rel='noopener noreferrer' className="btn-purple-outline px-5 py-3">
                                        Read More
                                    </a>
                                </div>
                            )

                        })}
                    </div>
                </div>
                
                {/* displays only on tablets  */}
                <div className="d-none d-md-block d-lg-none">
                    <ReactElasticCarousel itemsToShow={2.0} enableAutoplay={true} >
                        {articles?.slice(0,3).map((newArticle, index)=>{
                            return(
                                <div key={index} className="col-12 pe-3 my-4">
                                    <img src={newArticle?.publication?.image_banner} alt="" />
                                    <div style={{minHeight:"100px"}}>
                                        <h4 className="mt-4">{newArticle?.publication?.heading}.</h4>
                                    </div>
                                    
                                    <p className='article'>
                                        {decodeHTML(newArticle?.publication?.body?.replace(removeTags, "")) }
                                        {/* {newArticle?.description?.substr(109)} */}
                                    </p>
                                    <div className="dateTime">
                                        <i class="ri-calendar-line me-2"/>
                                        <span>
                                            <small>{newArticle?.publication?.published_dated}</small>
                                        </span>
                                    </div>
                                    <a href={`https://blog.myidentitypass.com/fullArticle/${newArticle?.slug}`}  target="_blank" rel='noopener noreferrer' className="btn-purple-outline px-5 py-3">
                                        Read More
                                    </a>
                                </div>
                            )
                        })}
                        {/* <div className="col my-4">
                            <img src={blogThree} alt="" />
                            <h4 className="mt-4">How Verification secures Crypto Transactions: Our Work With Bundle Africa.</h4>
                            <p>
                                Information is powerful but accurate data is more powerful. 
                                Making decisions based on trends, statistical numbers and fact...
                            </p>
                            <div className="dateTime">
                                <i class="ri-calendar-line me-2"/>
                                <span>
                                    <small>July 10, 2020</small>
                                    <small className="ms-3">4 mins read</small>
                                </span>
                            </div>
                            <a className=" btn-purple-outline px-5 py-3">
                                Read More
                            </a>
                        </div> */}
                    </ReactElasticCarousel>
                </div>

                {/* displays only on mobile  */}
                <div className=" d-md-none">
                    <ReactElasticCarousel itemsToShow={1.05} enableAutoplay={true} >
                        
                        {articles?.slice(0,3).map((newArticle, index)=>{
                            return(
                                <div className="col-12 pe-3 my-4">
                                    <img src={newArticle?.publication?.image_banner} alt="" />
                                    <div style={{minHeight:"100px"}}>
                                        <h4 className="mt-4">{newArticle?.publication?.heading}.</h4>
                                    </div>
                                    
                                    <p className='article'>
                                        {decodeHTML(newArticle?.publication?.body?.replace(removeTags, "")) }
                                    </p>
                                    <div className="dateTime">
                                        <i class="ri-calendar-line me-2"/>
                                        <span>
                                            <small>{newArticle?.publication?.published_dated}</small>
                                        </span>
                                    </div>
                                    <a href={`https://blog.myidentitypass.com/fullArticle/${newArticle?.slug}`}   target="_blank" rel='noopener noreferrer' className="btn-purple-outline px-5 py-3">
                                        Read More
                                    </a>
                                </div>
                            )
                        })}

                    </ReactElasticCarousel>
                </div>

            </div>
        </div>
    )
}
