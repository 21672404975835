import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className="footer-area py-5">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-3 col-lg-3 mt-5 ">
                        <h4>Identitypass</h4>
                        <div className='mt-4'>
                            <Link to="/Identity-Verification" className="link"> 
                                <p className="">Identity Verification</p> 
                            </Link>
                        </div>
                        <Link to="/Identity-Graph" className="link"> 
                            <p className="">Identity Graph</p> 
                        </Link>
                        <Link to="/Identity-Radar" className="link"> 
                            <p className="">Identity Radar</p> 
                        </Link>
                        {/* <p>Use Cases</p> */}
                        <Link to="/Pricing" className="link"> <p>Pricing</p> </Link>
                    </div>

                    <div className="col-6 col-md-3 col-lg-3 mt-5 ">
                        <h4>Help & Support</h4>
                        <div className=' mt-4'>
                            <Link to="/FAQs" className="link"> 
                                <p className="mt-4">FAQs</p>
                            </Link>
                        </div>
                        <Link to="/About-Us" className="link"> 
                            <p>About Us</p>
                        </Link>
                        <a href="mailto:team@myidentitypass.com" className="link" target="_blank" rel="noreferrer">
                            <p>Contact Us</p>
                        </a>
                        <a href="https://myidentitypass.bamboohr.com/jobs/" className="link" target="_blank" rel="noreferrer">
                            <p>Careers</p>
                        </a>
                        {/* <a href="/#partnerSponsor" className="link"> <p>Partner or Sponsor</p> </a> */}
                    </div>
                    <div className="col-6 col-md-3 col-lg-3 mt-5 ">
                        <h4>Resources</h4>
                        <div className='mt-4'>
                            <a href="https://developer.myidentitypass.com" className="link" target="_blank" rel="noreferrer">
                                <p>Documentation</p>
                            </a>
                        </div>
                        <a href="https://blog.myidentitypass.com" className="link" target="_blank" rel="noreferrer">
                            <p>Blog</p>
                        </a>
                        <Link to="/Privacy" className="link"> 
                            <p>Privacy Statement</p>
                        </Link>
                    </div>
                    {/* <div className="col-6 col-md-3 col-lg-3 mt-5 ">
                        <h4>Legal</h4>
                        <p className="mt-4">Terms & Conditions</p>
                        <p>About Us</p>
                        <p>Contact Us</p>
                    </div> */}
                    <div className="col-6 col-md-3 col-lg-3 mt-5 ">
                        <h4>Follow Us</h4>
                        <p className="mt-4 socials">
                            <a href="https://www.facebook.com/identitypass" className="link" target="_blank" rel="noreferrer">
                                <i class="ri-facebook-fill" />
                            </a>
                            <a href="https://twitter.com/myidentitypass" className="link" target="_blank" rel="noreferrer">
                                <i class="ri-twitter-fill" />
                            </a>
                            <a href="https://www.instagram.com/myidentitypass/" className="link" target="_blank" rel="noreferrer">
                                <i class="ri-instagram-fill" />
                            </a>
                            <a href="https://www.linkedin.com/company/my-identity-pay/" className="link" target="_blank" rel="noreferrer">
                                <i class="ri-linkedin-fill" />
                            </a>
                        </p>
                    </div>
                </div>
                <div className="text-center mt-5 pt-5">
                    <p>IdentityPass Nigeria Limited</p>
                </div>
            </div>
        </div>
    )
}
