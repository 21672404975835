import React, { useState } from 'react'
// import radarOne from '../../assets/id-radar/radar-1.png'
// import radarTwo from '../../assets/id-radar/radar-2.png'
// import radarThree from '../../assets/id-radar/radar-3.png'
import Blogcard from '../../components/blog-card'
import ReactElasticCarousel from 'react-elastic-carousel'
import Partners from '../../components/partners'


export default function IdRadar() {
    const [moreGraphPage, setMoreGraphPage] = useState(1)
    return (
        <div>
            <section className="id-checker-hero id-radar-hero">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-7 py-5">
                            <h1>Identity Radar</h1>
                            <p>
                                Provides a quick integration into your customers’ day-to-day transaction activities, 
                                evaluates misfits, and tracks fraudulent activities in real-time—leverage machine data 
                                rules and insights to combat cyber security threats.
                            </p>
                            <a href='https://calendly.com/myidentitypay-demo/my-identity-pay-demo' target="_blank" rel='noopener noreferrer' className="btn btn-white px-5 py-3 mt-3">Talk to Sales</a>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="id-checker-channels py-md-5">
                <div className="container">
                    <div className="col-md-6 mx-auto text-md-center py-5">
                        <p>
                            Get all the juice in few steps
                        </p>
                        <h2>Subset of Products</h2>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648803608/idPass-web-assets/id-radar/radar-1_obu7a1.png" alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto ">
                                <h3> Onboarding Screening</h3>
                                <p className="my-4">
                                    Clarify that a customer is not a fraudster during onboarding 
                                    by deploying specific rules through Identity radar’s machine 
                                    learning engine and multi-factor authentication settings
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Firewall Engine</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <p className="tick me-2">
                                                <i className="ri-check-line"></i>
                                            </p>
                                            <p>ATO Detector</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648803608/idPass-web-assets/id-radar/radar-2_o2znwz.png" alt="" />
                        </div>
                        <div className="col-md-6 order-md-1">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto">
                                <h3>Ongoing Monitoring (Firewall Engine, ATO detector)</h3>
                                <p className="my-4">
                                    Continuously keep track of a customer’s or business’s transactions 
                                    to detect irregular activities through Machine Learning Intelligence 
                                    quickly.
                                </p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Firewall Engine</p>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>ATO Detector</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648803610/idPass-web-assets/id-radar/radar-3_zfagva.png" alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto">
                                <h3>Transaction Monitoring</h3>
                                <p className="my-4">
                                    Identity radar provides an exceptional framework to help you understand 
                                    a user’s transaction pattern, reduce chargeback activities on integrated 
                                    channels, and prevent fraud.
                                </p>
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Artificial Intelligence tools</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Accurate Risk scores</p>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Swift Compliance</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Simple Integration</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="more-identity-graph-area py-5" style={{backgroundColor:"#F7EDFC"}} >
                <div className="container">

                    <div className="row ">
                        <div className="col-md-6">
                            <h5>GET THE GIST</h5>
                            <h2>Seamless Integration</h2>
                            <p>
                                Easily connect with Identity Radar’s functionality through web libraries 
                                or mobile SDKs and take compliance further by leveraging both rules and 
                                machine learning data insight to combat cybersecurity challenges.
                            </p>
                        </div>
                            
                        <div className="col-md-6 ps-lg-5">
                            {moreGraphPage === 1 ? 
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="tick me-3">
                                                <i className="ri-check-line"></i>
                                            </div>
                                            <div>
                                                <h5>Get the ball rolling with our SDK</h5>
                                                <p>
                                                    Add an extra layer of verification to your website 
                                                    through our SDKs. Confirm your customers are truly who 
                                                    they claim to be using Identity verification solutions 
                                                    integrated into your existing verification system/tech 
                                                    stack with just a few steps. This keeps fraudsters out 
                                                    while real customers are onboarded in seconds.
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 2 ? 
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Integrate with our APIs</h5>
                                                <p>
                                                    You can take productivity and automation to the next level with any of our APIs
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 3 ? 
                                <>
                                    
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />

                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                                <p>
                                                    Create a customized Workflow that replicates your brand identity and 
                                                    blends with your verification needs through the identity checker widget.
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                                <p>
                                                    Create a customized Workflow that replicates your brand identity and 
                                                    blends with your verification needs through the identity checker widget.
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="ideal-customers every-industry-area py-5">
                <div className="container">
                    <div className="col-md-6 mx-auto text-center py-5">
                        <h2>Ideal Customers</h2>
                        <p>From Local to Global Organisations, all you have to do is integrate our APIs into your System.</p>
                    </div>
                    <div className="row d-none d-lg-flex">
                        <div className="col-md-3 mt-5">
                            <div className="card ">
                                <div className="card-body">
                                    <i className="ri-bank-line"/>
                                    <h1>Fintech <br /> Companies</h1>
                                    <p>
                                        Leverage advanced machine learning rules to identify, restrict 
                                        and reduce online financial fraud in no time. Access more customers 
                                        with less friction while staying compliant and competitive
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card ">
                                <div className="card-body">
                                    <i className="ri-wallet-line"/>
                                    <h1>Crypto <br /> Exchanges</h1>
                                    <p>
                                        Process multiple card payments without the risk of chargebacks and 
                                        AML or KYC fines. Use Identity Radar to verify cardholder identities, 
                                        authenticate logins, and reduce all cryptocurrency fraud rates. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h1>Loan <br /> Companies</h1>
                                    <p>
                                        Avoid customers with defaulting records and enlarge your market by 
                                        taking control of your credit risk analysis. Identity radar lets you 
                                        scale your loan business by reaching suitable applicants and ensuring they indeed are who they say they are.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h1 style={{fontSize:"1.9rem"}}>E-commerce <br /> Platforms</h1>
                                    <p>
                                        Identity radar is a trusted defense of your online store, empowering 
                                        you to guard against fraud and easily protect your valued customers. 
                                        Boost your revenue by reducing chargebacks and resources lost to fraudulent orders.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* displays only on tablet  */}
                    <div className="d-none d-md-block d-lg-none">
                        <ReactElasticCarousel itemsToShow={2.0} enableAutoplay={true} >
                            <div className="col me-4 my-4">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h1>Fintech <br /> Companies</h1>
                                        <p>
                                            Leverage advanced machine learning rules to identify, restrict 
                                            and reduce online financial fraud in no time. Access more customers 
                                            with less friction while staying compliant and competitive
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-4">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h1>Crypto <br /> Exchanges</h1>
                                        <p>
                                            Process multiple card payments without the risk of chargebacks and 
                                            AML or KYC fines. Use Identity Radar to verify cardholder identities, 
                                            authenticate logins, and reduce all cryptocurrency fraud rates. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1>Loan <br /> Companies</h1>
                                        <p>
                                            Avoid customers with defaulting records and enlarge your market by 
                                            taking control of your credit risk analysis. Identity radar lets you 
                                            scale your loan business by reaching suitable applicants and ensuring they indeed are who they say they are.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1 style={{fontSize:"1.9rem"}}>E-commerce <br /> Platforms</h1>
                                        <p>
                                            Identity radar is a trusted defense of your online store, empowering 
                                            you to guard against fraud and easily protect your valued customers. 
                                            Boost your revenue by reducing chargebacks and resources lost to fraudulent orders.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </ReactElasticCarousel>
                    </div>

                    
                    {/* displays only on mobile  */}
                    <div className="d-md-none">
                        <ReactElasticCarousel itemsToShow={1.05} enableAutoplay={true} >
                            <div className="col me-3 my-2">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h1>Fintech <br /> Companies</h1>
                                        <p>
                                            Leverage advanced machine learning rules to identify, restrict 
                                            and reduce online financial fraud in no time. Access more customers 
                                            with less friction while staying compliant and competitive
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-3 my-2">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h1>Crypto <br /> Exchanges</h1>
                                        <p>
                                            Process multiple card payments without the risk of chargebacks and 
                                            AML or KYC fines. Use Identity Radar to verify cardholder identities, 
                                            authenticate logins, and reduce all cryptocurrency fraud rates. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-3 my-2">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1>Loan <br /> Companies</h1>
                                        <p>
                                            Avoid customers with defaulting records and enlarge your market by 
                                            taking control of your credit risk analysis. Identity radar lets you 
                                            scale your loan business by reaching suitable applicants and ensuring they indeed are who they say they are.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1 style={{fontSize:"1.9rem"}}>E-commerce <br /> Platforms</h1>
                                        <p>
                                            Identity radar is a trusted defense of your online store, empowering 
                                            you to guard against fraud and easily protect your valued customers. 
                                            Boost your revenue by reducing chargebacks and resources lost to fraudulent orders.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ReactElasticCarousel>
                    </div>
                </div>
            </section>

            <Partners/>

            {/* <Newsletter/> */}

            <Blogcard/>

        </div>
    )
}
