import React, { useRef } from 'react'
import ReactElasticCarousel from 'react-elastic-carousel'

export default function MobilityPage() {
    const items = [
        {
            "title":"Staarm",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648794668/idPass-web-assets/mobility/staarm_cupfue.png",
            "story": " Identitypass provides a fast and secure method to verify vehicle numbers, names, and types with unique automated technology, helping prevent some of the most prevalent types of mobility fraud from occurring. Africa’s leading mobility platform Staarm through Identitypass provides their customers with suitable vehicles while fighting increasing fraud threats in the mobility industry and making travel more convenient, responsible, and safe.",
        },
        {
            "title":"Jekalo",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648794667/idPass-web-assets/mobility/jekalo_u7jubk.png",
            "story": " Jekalo offers a secured ride-sharing service to Nigerians by ensuring that riders share a ride with only trusted & verified people going in the same direction. Using Identitypass’s verification solution, Jekalo is consistent on its mission to certify that ride-sharing is convenient, affordable & fun.",
        },
        {
            "title":"Rent to Drive",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648794667/idPass-web-assets/mobility/rentToDrive_aq9ueo.png",
            "story": " By leveraging on Identitypass’s AI-powered identity and vehicle verification solutions, Rent to drive is creating a system where renters can affordably drive their choice of vehicles and where car owners can earn passive income without necessarily having to drive.",
        },
        {
            "title":"Velox Enyo",
            "img":"https://res.cloudinary.com/dkssurk1s/image/upload/v1648794671/idPass-web-assets/mobility/slider_smlkhk.png",
            "story": " Using Identitypass’s driver’s License endpoint, Velox is making fleet management easier for real vehicle owners who desire control, accountability, and more value from their fuel budget. Through Identitypass’s car chassis verification endpoint,  Velox can easily verify all important details about a vehicle and continue to serve its mission of being a consumption/expense tracking card for users to monitor and personalize their fuel experience.",
        },
    ]
    const itemsPerPage = 1
    const carouselRef = useRef(null);
    const totalPages = Math.ceil(items.length / itemsPerPage)
    let resetTimeout;
  return (
    <div>
        <section className="mobility-hero">
            <div className="row align-items-center">
                <div className="col-md-6 order-md-2">
                    <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648794674/idPass-web-assets/mobility/mobility-hero_yw8kue.png" alt="" className='w-100' />
                </div>
                <div className="col-md-6 col-lg-5 offset-lg-1 py-5 order-md-1 px-4">
                    {/* <div className='hero-texts' style={{paddingLeft:"3.5rem"}}> */}
                        <h6>VERIFICATION AND SECURITY SERVICE FOR</h6>
                        <h1>Mobility Providers</h1>
                        <p>
                            Vehicle rental and car-sharing spaces prioritize convenience and safety. 
                            Identitypass's simple and fast verification solutions guarantee a 
                            smooth experience for end-users and mobility players.
                        </p>
                        <a className="py-3 px-3 px-md-5 btn btn-white mt-5" href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" target="_blank" rel="noreferrer">Talk to Sales</a>
                    {/* </div> */}
                </div>
            </div>
        </section>

        <section className='py-5'>
            <div className="container py-5">
                <div className="row align-items-center">
                    <div className="col-md-7 order-md-2">
                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648794668/idPass-web-assets/mobility/possibilities_f3r6m9.png" alt="" className='w-100' />
                    </div>
                    <div className="col-md-5 order-md-1 mt-4">
                        <h5>WHAT WE DO</h5>
                        <h2>We enable mobility players to verify all customers and businesses they work with quickly.</h2>
                        <p>
                            With multiple service offerings, the company serves hundreds of brands in 
                            Fintech, insurance, Security, HR, and many other sectors ensuring the brands 
                            are secured from fraudsters.
                        </p>
                        <a className="py-3 px-3 px-md-5 btn btn-purple mt-5" href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer">Get Started</a>
                    </div>
                </div>
            </div>
        </section>

        
        <section className="id-checker-widget mobility-verif how-face-works py-5" >
                <div className="container">
                    <div className="col-md-8 mx-auto text-md-center py-5">
                        <h2>Our Mobility Verification Endpoints</h2>
                        <p>
                            Three (3) major verification endpoints for mobility that can be merge into one.
                        </p>
                    </div>
                    <div className=" core-values mobility-endpoints mb-5">
                        <div className="row">
                            <div className="col-md-4 mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648738944/idPass-web-assets/about-us/card-icon_gknouc.png" className="card-icon" alt="" />
                                        <h4>Driver’s Verification</h4>
                                        <p>Accurately verify Drivers identity using Driver's license</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648738944/idPass-web-assets/about-us/card-icon_gknouc.png" className="card-icon" alt="" />
                                        <h4>Plate Number Verification</h4>
                                        <p>Accurately verify Vehicles Information using Vehicles's plate number</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card">
                                    <div className="card-body">
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648738944/idPass-web-assets/about-us/card-icon_gknouc.png" className="card-icon" alt="" />
                                        <h4>Chasis Number Verification</h4>
                                        <p>Access all information about a vehicle using the vehicle's identification number (VIN)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobility-slider'>
                        <ReactElasticCarousel enableAutoPlay={true} autoPlaySpeed={3000}
                            ref={carouselRef}
                            onNextEnd={({ index }) => {
                                clearTimeout(resetTimeout)
                                if (index + 1 === totalPages) {
                                    resetTimeout = setTimeout(() => {
                                       carouselRef?.current?.goTo(0)
                                    }, 3000)
                                }
                            }}
                            itemsToShow={itemsPerPage}
                        >
                            {items.map(value => {
                                return(
                                    <div className="row align-items-center">
                                        <div className="col-md-7 order-md-2">
                                            <img src={value.img} alt="" className='w-100' />
                                        </div>
                                        <div className="col-md-5 py-5 px-md-5 order-md-1 px-4">
                                            <h5>HOW THEY LEVERAGE IDENTITYPASS</h5>
                                            <h3>{value.title}</h3>
                                            <p>
                                                {value.story}
                                            </p>
                                            <a className="py-3 px-3 px-lg-5 btn btn-purple mt-5" href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" target="_blank" rel="noreferrer">Contact Sales</a>
                                        </div>
                                    </div>
                                )
                            })}
                        </ReactElasticCarousel>
                    </div>
                </div>
            </section>

        <section className="id-verif-steps py-5">
            <div className="container">
                <div className=" mb-5 text-center line">
                    <p>
                        How you can connect with us
                    </p>
                    <h2>How the process works</h2>
                </div>
                <div className="row text-center">
                    <div className="col-md-4 mt-3">
                        <span>
                            <h3> 1 </h3>
                        </span>
                        <h5>Via APIs</h5>
                        <p>You can easily connect with any of our endpoint using the available APIs</p>
                    </div>
                    <div className="col-md-4 mt-5">
                        <span className="li">
                            <h3> 2 </h3>
                        </span>
                        <h5>Via SDKs</h5>
                        <p>We have our SDKs in FLUTTER, REACT NATIVE and REACT JS.</p>
                    </div>
                    <div className="col-md-4 mt-3">
                        <span>
                            <h3> 3 </h3>
                        </span>
                        <h5>From the Portal</h5>
                        <p>We offer manual verification from our dashboard.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5">
            <div className="container">
                <div className="col-md-8 text-center mx-auto pt-5  pb-3">
                    <h1>Our Pricing</h1>
                    <p>
                        Our enterprise pricing is custom based. Schedule a call 
                        with us an tell us your use cases and we will tailor 
                        your pricing.
                    </p>
                </div>
            </div>
        </section>

        <section  className="pricing-card py-md-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-4 mt-4 p-md-0">
                        <div className="card">
                            <div className="card-body">
                                <i class="ri-loader-4-line loader" />
                                <h5>Standard Pricing</h5>
                                <small>You can pay as you make your calls</small>
                                <h3>$0.048</h3>
                                <h6>Per Successful API Call</h6><hr />
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p> Free monthly credit.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Access all APIs documentation and end points.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Technical Support</p>
                                </div>
                                <a className="py-3 btn btn-purple" 
                                    href="https://dashboard.myidentitypass.com/login" 
                                    target="_blank" rel="noreferrer"
                                >Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 mt-4 p-md-0 blue">
                        <div className="card">
                            <div className="card-body">
                                <div className="header">
                                    <i class="ri-loader-4-line loader" />
                                    <span>Recommended</span>
                                </div>
                                <h5>Premium Access</h5>
                                <small>With premium plan comes with more access</small>
                                <h3>Custom</h3>
                                <h6>Seat and pick endpoint to access</h6><hr />
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p> Request for other biometrics needs for your business and we will get it Delivered.</p>
                                </div>
                                <a className="py-3 btn btn-white" 
                                    href="https://dashboard.myidentitypass.com/login" 
                                    target="_blank" rel="noreferrer"
                                >Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-4 p-md-0">
                        <div className="card">
                            <div className="card-body">
                                <div className="header">
                                    <i class="ri-loader-4-line loader" />
                                    <span>Recommended</span>
                                </div>
                                <h5>Enterprice Pricing</h5>
                                <small>This pricing plan gives you every access to our APIs</small>
                                <h3>Full Access</h3>
                                <h6>Get full Access to all our Endpoints</h6><hr />
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p> Biometrics liveliness checker API.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>ID-Face Biometrics API.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                    <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Authentication API.</p>
                                </div>
                                <div class="d-flex align-items-start">
                                     <p class="tick me-2">
                                        <i class="ri-check-line"></i>
                                    </p>
                                    <p>Verification API and many more.</p>
                                </div>
                                <a className="py-3 btn btn-purple" 
                                    href="https://dashboard.myidentitypass.com/login" 
                                    target="_blank" rel="noreferrer"
                                >Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="across-africa-area py-5">
            <div className="container py-3">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h3>We can Easily Verify Your Customers</h3>
                        <p>1. <span style={{fontFamily:"GT-bold"}}>National IDs</span> -  Verify your customers national record</p>
                        <p>2. <span style={{fontFamily:"GT-bold"}}>Bank verification numbers</span> - We can inform you about the banking details of your customers</p>
                        <p>3. <span style={{fontFamily:"GT-bold"}}>Phone Number verification</span> - Verify or onboard your customers using the phone number</p>
                        <p>4. <span style={{fontFamily:"GT-bold"}}>Credit Histories</span> - Verify the credit histories of your customer</p>
                        <p>5. <span style={{fontFamily:"GT-bold"}}>Tax record</span> - Verify whether the tax record of your customer is accurate</p>
                        <div className="row pe-2">
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716892/idPass-web-assets/nigeria-flag_cwwg8t.png" alt="" className="w-100" />
                                <h5>Nigeria</h5>
                            </div>
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716892/idPass-web-assets/kenya-flag_gqxlg2.png" alt="" className="w-100" />
                                <h5>Kenya</h5>
                            </div>
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716891/idPass-web-assets/ghana-flag_uzl7bg.png" alt="" className="w-100" />
                                <h5>Ghana</h5>
                            </div>
                            <div className="col-3">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716893/idPass-web-assets/sa-flag_uagypd.png" alt="" className="w-100"/>
                                <h5>South Africa</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716890/idPass-web-assets/Doted-African-Map_qxyxfb.png" alt="" className="w-100 mt-4" />
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
