import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'

export default function Faq() {
    const [activeFaq, setActiveFaq] = useState(0)
    return (
        <div>
            
            <section className="faq">
                <div className="container py-5">
                    <div className="col-md-8 mx-auto text-center">
                        <p>You’ve Got Questions? We Got Answers</p>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                </div>
                <div style={{background:"#F4F5F7"}} className="py-5">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <Accordion>
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(0)}} >
                                        <p className=" question accordion-button" >
                                            What are the verification services offered by Identitypass?
                                        </p>
                                    </div>
                                    {activeFaq === 0 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(1)}}>
                                        <p className=" question accordion-button" >
                                            How long does Integration take?
                                        </p>
                                    </div>
                                    {activeFaq === 1 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We have a very smooth integration process that takes about 24 
                                                hours or less to integrate with our API. 
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(2)}}>
                                        <p className=" question accordion-button" >
                                            Do we get charged for failed API calls?
                                        </p>
                                    </div>
                                    {activeFaq === 2 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                No, we do not charge for failed calls. You only get charged for successful calls. 
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(3)}}>
                                        <p className=" question accordion-button" >
                                        How do we handle issues that may arise ?
                                        </p>
                                    </div>
                                    {activeFaq === 3 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                You can easily reach out to us by clicking on the icon located at the bottom
                                                right of your screen.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(4)}}>
                                        <p className=" question accordion-button" >
                                            How do I integrate into any platform I build?
                                        </p>
                                    </div>
                                    {activeFaq === 4 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                You can integrate with our verification systems via APIs.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(5)}}>
                                        <p className=" question accordion-button" >
                                            Do you also provide Biometric verification services?
                                        </p>
                                    </div>
                                    {activeFaq === 5 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                Yes, we do. We provide a Liveliness check, antispoofing and facial recognition. 
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(6)}}>
                                        <p className=" question accordion-button" >
                                        How often do you experience system downtimes?
                                        </p>
                                    </div>
                                    {activeFaq === 6 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                This happens on rare occasions, but we always communicate to our clients when we do experience downtimes to ensure they always stay informed. 
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(7)}}>
                                        <p className=" question accordion-button" >
                                        How do we fund our wallet ?
                                        </p>
                                    </div>
                                    {activeFaq === 7 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                You can fund your wallet from your dashboard using your credit or debit card. 
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(8)}}>
                                        <p className=" question accordion-button" >
                                            Is there a Test Environment?
                                        </p>
                                    </div>
                                    {activeFaq === 8 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                Our Sandbox environment allows you to test APIs before integrating into the live environment.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(9)}}>
                                        <p className=" question accordion-button" >
                                            Do i need my CAC document to activate my merchant account?
                                        </p>
                                    </div>
                                    {activeFaq === 9 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                In compliance with the NIMC NIN tokenization requirement, you need 
                                                to upload your CAC document for verification purposes
                                            </p>
                                        </div>
                                    }
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
