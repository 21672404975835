import React from 'react'
import Blogcard from '../../components/blog-card'
import Faq from '../../components/faq'

export default function Career() {
    return (
        <div>
            <section className="career-hero">
                <div className="container">
                    <div className="col-md-7 mx-auto">
                        <h1>Career</h1>
                        <p>
                            Join Our Amazing Team at IdentityPass
                        </p>
                    </div>
                </div>
            </section>

            <section className=" open-positions py-5">
                <div className="container py-5">
                    <h2>Open Positions</h2>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Engineering</h5>
                                        <h6>1 open role</h6>
                                    </div>
                                    <div className="my-4">
                                        <h5>Lead Product Engineer</h5>
                                        <p>
                                            Lorem ipsum dolor amit because I do not know what to 
                                            write and how to write it. Yes! This is a job role.
                                        </p>
                                    </div>
                                    <a href=""><h6>Click here to apply</h6></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Human Resources</h5>
                                        <h6>1 open role</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Finance</h5>
                                        <h6>1 open role</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Compliance</h5>
                                        <h6>1 open role</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Design</h5>
                                        <h6>1 open role</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Expansion and Partnerships</h5>
                                        <h6>1 open role</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="col-md-7 mx-auto text-center">
                        <p>Chief, Shoot your shot.</p>
                        <h1>Can’t Find The Right role?</h1>
                    </div>
                    <div className="col-md-9 mx-auto py-5 px-4 my-5" style={{background:"#1633A3", color:"#fff"}}>
                        <div className="row">
                            <div className="col-md-8">
                                <h5>Email your resume to team@myidentitypass.com</h5>
                                <p>To be considered for new positions in the future.</p>
                            </div>
                            <div className="col-md-4">
                                <button style={{color:"#303AF2"}} className=" btn btn-white px-5 py-3">
                                    Email Resume
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faq/>

            <Blogcard/>
        </div>
    )
}
