import React, {useState} from 'react'
import kehinde from '../../assets/kehinde.svg'
import {Carousel} from '3d-react-carousal';
// import Newsletter from '../../components/newsletter'
import Blogcard from '../../components/blog-card'
import ReactElasticCarousel from 'react-elastic-carousel'

export default function HomePage() {
    const [moreGraphPage, setMoreGraphPage] = useState(1)
    const [howToUseDataPage, setHowToUseDataPage] = useState(1)
    
    let slides = [
        <div className="col-md-7 col-lg-5 px-md-4 my-3">
            <div className="card ">
                <div className="card-header">
                    <i className="ri-double-quotes-l"/>
                </div>
                <div className="card-body">
                    <p>
                        Your verification solution have been good. We appreciate 
                        the timely updates and resolutions we receive.
                    </p>
                    <div className="card-footer">
                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1649061959/idPass-web-assets/eno_dqnhh6.png" alt="" />
                        <span>
                            <h5>Eno</h5>
                            <p>Software Engineer -- Bundle</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>,
        <div className="col-md-7 col-lg-5 px-md-4 my-3">
            <div className="card ">
                <div className="card-header">
                    <i className="ri-double-quotes-l"/>
                </div>
                <div className="card-body">
                    <p>
                        Identitypass verification product has been great 
                        so far. My team and I enjoy the services we receive 
                        and we don’t have compliants.
                    </p>
                    <div className="card-footer">
                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1649061959/idPass-web-assets/dickson_m3fl4p.png" alt="" />
                        <span>
                            <h5>Dickson</h5>
                            <p>Lendigo Software Engineer</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>,
        <div className="col-md-7 col-lg-5 px-md-4 my-3">
            <div className="card ">
                <div className="card-header">
                    <i className="ri-double-quotes-l"/>
                </div>
                <div className="card-body">
                    <p>
                        I still recommend your service to a product manager 
                        two weeks ago. I think you've got a great service. 
                        Plus, the support service is exceptional.
                    </p>
                    <div className="card-footer">
                        <img src={kehinde} alt="" />
                        <span>
                            <h5>Kehinde</h5>
                            <p>Product Manager -- Credpal</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>,
    ];
    return (
        <div>
            <section className="hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 ">
                            <div className="px-3">
                                <h1>Establishing online trust</h1>
                                <p>
                                    Identitypass offers authentic products to help you verify, 
                                    gain deeper insights about users/businesses, and prevent fraud in real-time.
                                </p>
                                {/* <a className="py-3 px-3 px-md-5 btn btn-white my-2 me-4" href="" target="_blank" rel="noreferrer ">Take a Tour</a> */}
                                <a className="py-3 px-3 px-md-5 btn btn-white my-2 me-4" href="https://checkout-widget.myidentitypass.com/" target="_blank" rel="noreferrer ">View Demo</a>
                                <a className="btn py-3 px-4 px-md-5 btn btn-white-outline my-2" href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer"> Get Started </a>                        
                            </div>
                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            {/* <img src={chartImg} alt="" className=''/> */}
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716889/idPass-web-assets/chart_wttthq.png" alt="Identitypass ID verification Analytics" className=''/>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="partnerSponsor" className="partners-area pb-5">
                <div className="container">
                    <div className="card py-3">
                        <div className="card-body col-md-10 mx-auto text-center">
                            <h6>Partners and Customers</h6>
                            <h5>We've Been Trusted By</h5>
                            <div className="row align-items-center justify-content-around py-3">
                                <div className="col-2">
                                    <img src={credpalLogo} alt="" />
                                </div>
                                <div className="col-2">
                                    <img src={mintLogo} alt="" />
                                </div>
                                <div className="col-2">
                                    <img src={GDCLogo} alt="" />
                                </div>
                                <div className="col-2">
                                    <img src={teamaptLogo} alt="" />
                                </div>
                                <div className="col-2">
                                    <img src={bundleLogo} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <Features /> */}

            <section className="py-5">
                <div className="col d-lg-none">
                    <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716893/idPass-web-assets/resp-chart_ojje68.png" className='w-100' alt="Identitypass ID verification Analytics" />
                </div>
                <div className="container-fluid pe-0 py-3" style={{overflow:"hidden"}}>
                    <div className="row align-items-center">
                        <div className="offset-lg-1 col-lg-3">
                            <h3>The Magic with Identity Verification</h3>
                            <p className="py-4">
                                Authenticate 6,000+ government-issued identity documents 
                                from over 190 countries in a matter of seconds on web and mobile
                            </p>
                        </div>
                        <div className="col-lg-8">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716894/idPass-web-assets/app-view_mmrod4.png" alt="ID-checker Verification dashboard" className="w-100" />
                        </div>
                    </div>
                </div>
            </section>

            <section className='how-to-use-data-area'>
                <div className="container py-5">
                    <div className=' col-lg-8 my-4 tabs'>
                        <p className={howToUseDataPage === 1 ?"active" : ""} onClick={()=>setHowToUseDataPage(1)}>
                            <i class="ri-bubble-chart-line"/>
                            Data Verification
                        </p>
                        <p className={howToUseDataPage === 2 ?"active" : ""} onClick={()=>setHowToUseDataPage(2)}>
                            <i class="ri-loader-4-line"/>
                            Biometric Authentication
                        </p>
                        <p className={howToUseDataPage === 3 ?"active" : ""} onClick={()=>setHowToUseDataPage(3)}>
                            <i class="ri-checkbox-circle-line"/>
                            Document Verification
                        </p>
                    </div>
                    <div className="row align-items-center">
                        {howToUseDataPage === 1 ?
                            <>
                                <div className="col-lg-8 mt-4">
                                    <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716896/idPass-web-assets/tabimg1_ucattc.png" alt="Instant Face Liveness checks" className="w-100" />
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <h3>How to use our Data Verification</h3>
                                    <p>
                                        You can easily integrate data searches to retrieve contact or user data. 
                                        Implementing Identitypass data search on your website/application 
                                        allows you to personalize web pages with contact or visitor information.
                                    </p>
                                    <div className="how-to-steps">
                                        <span className='py-1 px-3 btn-purple' style={{borderRadius:5}}>
                                            Steps
                                        </span> <br /> <br />
                                        <h5>Step One</h5>
                                        <p>
                                            Sign up on our portal to get access to our verification endpoints.
                                        </p>
                                        <h5>Step Two</h5>
                                        <p>
                                            Get your API keys and integrate our solutions with your website or Application.
                                        </p>
                                        <h5>Step Three</h5>
                                        <p>
                                            Get full access and start using our solutions.
                                        </p>
                                    </div>
                                </div>
                            </>
                            :
                            howToUseDataPage === 2 ?
                            <>
                                <div className="col-lg-8 mt-4">
                                    <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716895/idPass-web-assets/tabimg2_vl8l57.png" alt="AI-based document verification solution" className="w-100" />
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <h3>How to use our Biometric Authentication</h3>
                                    <p>
                                        Our Biometrics authentication solution relies on accurate machine learning 
                                        technology to ascertain individuals through their distinct physical characteristics, 
                                        such as a fingerprint, facial patterns, or iris or retinal patterns.
                                    </p>
                                    <div className="how-to-steps">
                                        <span className='py-1 px-3 btn-purple' style={{borderRadius:5}}>
                                            Steps
                                        </span> <br /> <br />
                                        <h5>Step One</h5>
                                        <p>
                                            Sign up on our portal to get access to our verification endpoints.
                                        </p>
                                        <h5>Step Two</h5>
                                        <p>
                                            Get your API keys and integrate our solutions with your website or Application
                                        </p>
                                        <h5>Step Three</h5>
                                        <p>
                                           Get full access start using our solutions
                                        </p>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-lg-8 mt-4">
                                    <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716894/idPass-web-assets/tabimg3_d9rzcd.png" alt="" className="w-100" />
                                </div>
                                <div className="col-lg-4 mt-4">
                                    <h3>How to use our Document Verification</h3>
                                    <p>
                                        Our AI-based authentication solution allows your business to quickly and accurately 
                                        confirm the identity of a user/business through particular documents such as 
                                        driver's license, electoral card, passports, and CAC documents.
                                    </p>
                                    <div className="how-to-steps">
                                        <span className='py-1 px-3 btn-purple' style={{borderRadius:5}}>
                                            Steps
                                        </span> <br /> <br />
                                        
                                        {/* <h5>1 Step One</h5>
                                        <p>
                                            With our powerful features Identitypass offers a 
                                            quick and accurate solution.
                                        </p> */}

                                        <h5>Step One</h5>
                                        <p>
                                            Sign up on our portal to get access to our verification endpoints.
                                        </p>
                                        <h5>Step Two</h5>
                                        <p>
                                            Get your API keys and integrate our solutions with your website or Application
                                        </p>
                                        <h5>Step Three</h5>
                                        <p>
                                           Get full access start using our Solutions
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>

            
            <section className="id-checker-widget how-face-works py-5" >
                <div className="container">
                    <div className="col-md-7 mx-auto text-md-center py-5">
                        <h2>A Step Further with Identity Radar </h2>
                        <p>
                            Check users' and businesses' identities across different networks to 
                            know who your customers are and their activities to prevent and detect fraud.
                        </p>
                    </div>

                    <div className="row justify-content-between">
                        <div className="col-6 col-md-4 col-lg-3">
                            <i class="ri-stack-line" />
                            <h5>Onboarding Screening</h5>
                            <p>
                                Identity radar integrates more into your customer's daily transactions by tracking 
                                real-time fraudulent activities at the point of customer onboarding.
                            </p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <i class="ri-creative-commons-nd-line" />
                            <h5>Ongoing Monitoring</h5>
                            <p>
                                Identity radar monitors your activities and gets transparent fraud scores on open 
                                transactions in a second with our automated AI-driven workflow.
                            </p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <i class="ri-settings-2-line" />
                            <h5>Transaction Monitoring </h5>
                            <p>
                                Identity radar furthers compliance by leveraging rules and machine learning 
                                data insight to combat cyber security challenges.
                            </p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <i class="ri-bubble-chart-line" />
                            <h5>Fraud Detection</h5>
                            <p>Identity radar automatically flags down fraudulent activities at the point of discovery.</p>
                        </div>
                    </div>
                    <div className="col-md-9 mx-auto mt-5 video-area">
                        <video className='w-100' controls poster='https://res.cloudinary.com/dkssurk1s/image/upload/v1648828141/idPass-web-assets/videoImg_eqowvd.png'>
                            <source src="https://res.cloudinary.com/dkssurk1s/video/upload/v1648716933/idPass-web-assets/video_sdbtsx.mp4" type="video/mp4" alt="Video-Introducing Identitypass's KYC, KYB and digital compliance products" />
                            Your browser can not play this video.
                        </video>
                    </div>
                </div>
            </section>

            <section style={{backgroundColor:"#F0F3FB"}}>
                <div className="container py-5 mt-5">
                    <div className="row align-items-center">
                        <div className="col-md-7 order-md-2 mt-5">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716891/idPass-web-assets/graphImg_gi4nb9.png" alt="" className="w-100" />
                        </div>
                        <div className="col-md-5 order-md-1 mt-5">
                            <h5>EXPLORE THE SYSTEM</h5>
                            <h1 className='mt-4'>Identity Graph</h1>
                            <p className='my-5'>
                                Understand your customers by discovering their connections to several other 
                                data points globally—leverage our dynamic verification tool to detect past fraudsters' activities in real-time.
                            </p>
                            <a className="py-3 px-3 px-md-5 btn btn-purple my-2" href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" target="_blank" rel="noreferrer">Contact Sales</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className='more-identity-graph-area py-5'>
                <div className="container py-5">
                    <h5>GET THE GIST</h5>
                    <h2>More on Identity Graph</h2>
                    <div className="row mt-5 align-items-center">
                        <div className="col-lg-6 d-none d-lg-block">
                            {moreGraphPage === 1 ? 
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/moreGraph1_hry8my.png" alt=""  className='w-100'/>
                                :
                                moreGraphPage === 2 ? 
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/moreGraph2_mw0zcs.png" alt=""  className='w-100'/>
                                :
                                moreGraphPage === 3 ? 
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/moreGraph3_q2sbqe.png" alt=""  className='w-100'/>
                                : 
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716896/idPass-web-assets/moreGraph4_wnkzzo.png" alt=""  className='w-100'/>
                            }
                        </div>
                        <div className="col-lg-6 ps-lg-5">
                            {moreGraphPage === 1 ?
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="tick me-3">
                                                <i class="ri-check-line"></i>
                                            </div>
                                            <div>
                                                <h5>Deep Relationship Insight Infrastructure</h5>
                                                <p>
                                                    Identity Graph is a vast digital infrastructure that helps users determine 
                                                    relationships, people, social media, and any digital footprints linked to any identity.
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/moreGraph1_hry8my.png" alt=""  className='w-100 d-lg-none'/>
                                    </div>

                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line ri-xl me-3" />
                                        <h5>Custom Data Source to gain further deeper insight</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=>setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line ri-xl me-3"/>
                                        <h5>Analytics that work for both Techy & Non Techy</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=>setMoreGraphPage(4)}>
                                        <i className="ri-alert-line ri-xl me-3"/>
                                        <h5>Data Visualization (Visual Mode & Data Mode)</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 2 ?
                                <>

                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Deep Relationship Insight Infrastructure</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Custom Data Source to gain further deeper insight</h5>
                                                <p>
                                                    Identity Graph allows users to plug in an existing dataset ranging from 
                                                    different industries (Fin-tech, Transportation, health, and many more).
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/moreGraph2_mw0zcs.png" alt=""  className='w-100 d-lg-none'/>
                                    </div>
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=>setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line ri-xl me-3"/>
                                        <h5>Analytics that work for both Techy & Non Techy</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=>setMoreGraphPage(4)}>
                                        <i className="ri-alert-line ri-xl me-3"/>
                                        <h5>Data Visualization (Visual Mode & Data Mode)</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 3 ?
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Deep Relationship Insight Infrastructure</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line ri-xl me-3" />
                                        <h5>Custom Data Source to gain further deeper insight</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i className="ri-qr-scan-2-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Analytics that work for both Techy & Non Techy</h5>
                                                <p>
                                                    AI-powered analytics: With a data set mapped to an identity(individual or organization), 
                                                    the Identity graph gives insights and analytics using trained machine learning models.
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/moreGraph3_q2sbqe.png" alt=""  className='w-100 d-lg-none'/>
                                    </div>
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=>setMoreGraphPage(4)}>
                                        <i className="ri-alert-line ri-xl me-3"/>
                                        <h5>Data Visualization (Visual Mode & Data Mode)</h5>
                                    </div><hr />
                                </>
                                :
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Deep Relationship Insight Infrastructure</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line ri-xl me-3" />
                                        <h5>Custom Data Source to gain further deeper insight</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=>setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line ri-xl me-3"/>
                                        <h5>Analytics that work for both Techy & Non Techy</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i className="ri-alert-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Data Visualization (Visual Mode & Data Mode)</h5>
                                                <p>
                                                    With our powerful features, you can quickly determine relationships, people, 
                                                    social media, or any digital footprints linked to any identity while accurately 
                                                    gaining insights and analytics using trained machine learning models
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/login" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                        <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716896/idPass-web-assets/moreGraph4_wnkzzo.png" alt=""  className='w-100 d-lg-none'/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5' style={{backgroundColor:"#F7EDFC"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716892/idPass-web-assets/graphImg2_rycwqu.png" alt="" className='w-100' />
                        </div>
                        <div className="col-md-6">
                            <h5>IDENTITYPASS</h5>
                            <h2 className='mt-4'>Our Core Infrastructure</h2>
                            <p className='my-5'>
                                Simplicity is everything; Because trust-establishing tools should adapt to your 
                                business, all our integration channels are designed with usability. We protect 
                                your business and customers by quickly complying with local and international 
                                KYC/AML regulations through our GDPR compliance infrastructure.
                            </p>

                            <div class="d-flex align-items-center">
                                <p class="tick me-2">
                                    <i class="ri-check-line"></i>
                                </p>
                                <p> Seamless User Experience</p>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="tick me-2">
                                    <i class="ri-check-line"></i>
                                </p>
                                <p> Ease of Use</p>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="tick me-2">
                                    <i class="ri-check-line"></i>
                                </p>
                                <p> Robust Compliance</p>
                            </div>
                            <a className="py-3 px-3 px-md-5 btn btn-black my-2" href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" target="_blank" rel="noreferrer">Contact Sales</a>

                        </div>
                    </div>
                </div>

            </section>

            
            <section className="every-industry-area py-5">
                <div className="container">
                    <div className="col-md-8 col-lg-6 mx-auto text-md-center py-5">
                        <h2>Other Features</h2>
                        <p>
                            Other Features that makes the product much more effective.
                        </p>
                        
                    </div>
                    {/* display only on system */}
                    <div className="row d-none d-lg-flex">
                        <div className="col-md-3 mt-5">
                            <div className="card" style={{backgroundColor:"#5B9FF0", color:"#fff"}}>
                                <div className="card-body">
                                    <i className="ri-bank-line"/>
                                    <h2>Realtime activity monitoring</h2>
                                    <p>
                                        Our merchant's Dashboard allows you to monitor all verification activities, 
                                        such as (total verified IDs, Total valid and invalid IDs, recent API calls e.t.c.) 
                                        as they occur in real-time. With this, you can easily track your activities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card " style={{backgroundColor:"#9B17C7", color:"#fff"}}>
                                <div className="card-body">
                                    <i className="ri-wallet-line"/>
                                    <h2>No Code tools to quickly get started</h2>
                                    <p>
                                        Seamlessly implement Identitypass's verification APIs into your 
                                        tech stack through our manual integration endpoint in a few 
                                        steps directly from your dashboard.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card" style={{backgroundColor:"#1C32A5", color:"#fff"}}>
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h2>Collaborate with your Team</h2>
                                    <p>
                                        Smoothly engage your team members on your verification activities 
                                        from your merchant's dashboard. You can add other team members, 
                                        assign tasks and track each team member's activity in real time.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card" style={{backgroundColor:"#E59C8C", color:"#fff"}}>
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h2 style={{fontSize:"29.1px"}}>Developer Friendly Documentation</h2>
                                    <p>
                                        Our documentation page is structured in an uncomplicated pattern, and our script 
                                        is easy to understand for developers to deploy without experiencing any inconvenience 
                                        with coding technicalities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* displays only on tablet  */}
                    <div className="d-none d-md-block d-lg-none">
                        <ReactElasticCarousel itemsToShow={2.0} enableAutoplay={true} >
                            <div className="col me-4 my-3">
                                <div className="card" style={{backgroundColor:"#5B9FF0", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h2>Realtime activity monitoring</h2>
                                        <p>
                                            Our merchant's Dashboard allows you to monitor all verification activities, such 
                                            as (total verified IDs, Total valid and invalid IDs, recent API calls e.t.c.) as 
                                            they occur in real-time. With this, you can easily track your activities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-3">
                                <div className="card " style={{backgroundColor:"#9B17C7", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h2>No Code tools to quickly get started</h2>
                                        <p>
                                            Seamlessly implement Identitypass's verification APIs into your 
                                            tech stack through our manual integration endpoint in a few 
                                            steps directly from your dashboard.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-3">
                                <div className="card" style={{backgroundColor:"#1C32A5", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h2>Collaborate with your Team</h2>
                                        <p>
                                            Smoothly engage your team members on your verification activities 
                                            from your merchant's dashboard. You can add other team members, 
                                            assign tasks and track each team member's activity in real time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-3">
                                <div className="card" style={{backgroundColor:"#E59C8C", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h2 style={{fontSize:"29.1px"}}>Developer Friendly Documentation</h2>
                                        <p>
                                            Our documentation page is structured in an uncomplicated pattern, and 
                                            our script is easy to understand for developers to deploy without 
                                            experiencing any inconvenience with coding technicalities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ReactElasticCarousel>
                    </div>

                    
                    {/* displays only on mobile  */}
                    <div className="d-md-none">
                        <ReactElasticCarousel itemsToShow={1.05} enableAutoplay={true} >
                            <div className="col me-4 my-3">
                                <div className="card" style={{backgroundColor:"#5B9FF0", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h2>Realtime activity monitoring</h2>
                                        <p>
                                            Our merchant's Dashboard allows you to monitor all verification 
                                            activities such as ( total verified IDs, Total valid and invalid IDs,
                                            recent API calls e.t.c.) as they occur in real time. With this, you can 
                                            easily track your activities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-3">
                                <div className="card " style={{backgroundColor:"#9B17C7", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h2>No Code tools to quickly get started</h2>
                                        <p>
                                            Seamlessly implement Identitypass's verification APIs into your 
                                            tech stack through our manual integration endpoint in a few 
                                            steps directly from your dashboard.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-3">
                                <div className="card" style={{backgroundColor:"#1C32A5", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h2>Collaborate with your Team</h2>
                                        <p>
                                            Smoothly engage your team members on your verification activities 
                                            from your merchant's dashboard. You can add other team members, 
                                            assign tasks and track each team member's activity in real time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-3">
                                <div className="card" style={{backgroundColor:"#E59C8C", color:"#fff"}}>
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h2 style={{fontSize:"29.1px"}}>Developer Friendly Documentation</h2>
                                        <p>
                                            Our documentation page is structured in an uncomplicated pattern, and our script 
                                            is easy to understand for developers to deploy without experiencing any 
                                            inconvenience with coding technicalities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ReactElasticCarousel>
                    </div>
                </div>
            </section>


            
            <section className="customers-perspective-area">
                <div className="container">
                    <div className="col-md-8 col-lg-6 mx-auto text-center py-5">
                        <p>
                            We think we are great!
                        </p>
                        <h2>Our Customers Perspective</h2>
                    </div>
                    
                    <Carousel slides={slides} interval={1000}/>
                </div>
            </section>

            <section>
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716890/idPass-web-assets/fineIcons_dxpo4z.png" alt="" className='w-100' />
                        </div>
                        <div className="col-md-7">
                            <div className="col-md-9">
                                <p>Designed for Developers</p>
                                <h1>Enterprise-grade Security</h1>
                                <p>
                                    We adhere to best-in-class security recommendations, providing 
                                    solutions that align with global standards of utmost security.
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="d-flex align-items-center">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>Two-Factor Authentication</p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>Secured Verification Channels</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="d-flex align-items-center">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>PECB Compliants</p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>Excellent Fraud Detection Services</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="api-area py-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <p>Designed for developers</p>
                            <h1>Seamless API integration for Developers</h1>
                            <p>
                                Identitypass provides developers with Simplified and 
                                easy-to-use APIs integration for third-party websites 
                                and a sleek verification system for Onsite Verification users.
                            </p>
                            <a href="http://developer.myidentitypass.com/" target="_blank" rel='noopener noreferrer' className="btn btn-white px-md-5 py-3 mt-3 me-4">Explore our Documentation</a>
                            <a href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" target="_blank" rel='noopener noreferrer' className="btn btn-white-outline px-md-5 py-3 mt-3">Contact Sales</a>
                        </div>
                        <div className="col-md-5 d-flex justify-content-end">
                            <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716895/idPass-web-assets/api_p14zie.svg" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <Newsletter/> */}

            <Blogcard/>

        </div>
    )
}
