import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavbarArea from './components/navbar';
import Footer from './components/footer';
import HomePage from "./pages/home";
// import BioAuthPage from './pages/bioAuth';
// import IdChecker from './pages/idVerification';
import Pricing from './pages/pricing';
// import UseCasesPage from './pages/useCases';
import AboutUs from './pages/aboutUs';
// import IdDataVerif from './pages/idDataVerif';
import Career from './pages/career';
// import BlogPage from './pages/blog';
import ScrollToTop from './components/scrollToTop';
import IdVerification from './pages/idVerification';
import MobilityPage from './pages/mobility';
import IdGraph from './pages/idGraph';
import IdRadar from './pages/idRadar';
import FinancialServicesPage from './pages/financialServices';
import CryptoExchangesPage from './pages/cryptoExchanges';
import FAQPage from './pages/faq';
import PrivacyPage from './pages/privacy';

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop>
          <NavbarArea/>
          <Routes>
            <Route exact path="/" element={<HomePage/>} />
            <Route path="/Identity-Verification" element={<IdVerification/>} />
            <Route path="/Identity-Graph" element={<IdGraph/>} />
            <Route path="/Identity-Radar" element={<IdRadar/>} />
            <Route path="/Mobility" element={<MobilityPage/>} />
            <Route path="/Financial-Services" element={<FinancialServicesPage/>} />
            <Route path="/Crypto-Exchanges" element={<CryptoExchangesPage/>} />
            {/* <Route path="/Biometric-Authentication" element={<BioAuthPage/>} /> */}
            {/* <Route path="/Identity-Checker" element={<IdChecker/>} /> */}
            {/* <Route path="/Use-Cases" element={<UseCasesPage/>} /> */}
            <Route path="/About-Us" element={<AboutUs/>} />
            {/* <Route path="/Identity-Data-Verification" element={<IdDataVerif/>} /> */}
            <Route path="/Career" element={<Career/>} />
            {/* <Route path="/Blog" element={<BlogPage/>} /> */}
            <Route path="/Pricing" element={<Pricing/>} />
            <Route path="/FAQs" element={<FAQPage/>} />
            <Route path="/Privacy" element={<PrivacyPage/>} />
          </Routes>
          <Footer/>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
