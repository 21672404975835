import React from 'react'

export default function Partners() {
  return (
    <div>
        <section className='py-5'>
                <div className="container py-5">
                    <div className=" col-lg-8 mx-auto text-center">
                        {/* <h6>Partners and Customers</h6> */}
                        <h5>Customers and Partners Trusted By</h5>
                        <div className="row align-items-center justify-content-around py-3">
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716889/idPass-web-assets/credpal-logo_mzj7gr.png" alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716892/idPass-web-assets/mint-logo_x5uyzv.png" alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716893/idPass-web-assets/teamapt_abycck.png" alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716889/idPass-web-assets/cars45Logo_c53geh.png" alt="" />
                            </div>

                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716889/idPass-web-assets/Bundle-logo_ujibqj.png" alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716893/idPass-web-assets/pageLogo_zu0u8m.png" alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716890/idPass-web-assets/fairmoneyLogo_sz1zhu.png" alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src="https://res.cloudinary.com/dkssurk1s/image/upload/v1648716891/idPass-web-assets/GDCLogo_mmbltv.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}
